<template>
  <div>
    <b-card title="Randevuya Gelmeyen Müşteri Görüşme Notları">
      <b-row class="d-flex align-items-center">
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Servis Durumu"
            label-for="id_com_interview_status"
          >
            <v-select
              id="id_com_interview_status"
              v-model="id_com_interview_status"
              placeholder="Servis Durumu"
              :options="statuses"
              :reduce="item => item.id"
              :close-on-select="false"
              label="title"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            :href="downloadUrl"
            target="_blank"
            :disabled="!sdate || !edate || !id_com_interview_status"
          >
            <FeatherIcon icon="DownloadIcon" />
            Raporu İndir
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Randevuya Gelmeyen Müşteri Görüşme Notu Durumları">
      <b-row class="d-flex align-items-center">
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="status_sdate"
          >
            <b-form-datepicker
              id="status_sdate"
              v-model="status_sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="status_edate"
          >
            <b-form-datepicker
              id="status_edate"
              v-model="status_edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Temas"
            label-for="id_com_meet"
          >
            <v-select
              id="id_com_meet"
              v-model="id_com_meet"
              placeholder="Temas"
              :options="meets"
              :reduce="item => item.id"
              :close-on-select="false"
              label="title"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            :href="status_downloadUrl"
            target="_blank"
            :disabled="!status_sdate || !status_edate"
          >
            <FeatherIcon icon="DownloadIcon" />
            Raporu İndir
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ServiceTracingInterviews',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  data() {
    return {
      sdate: null,
      edate: null,
      status_sdate: null,
      status_edate: null,
      id_com_interview_status: null,
      id_com_meet: null,
      statuses: [{
        id: 0,
        title: 'Açık',
      },
      {
        id: 1,
        title: 'Kapalı',
      },
      ],
    }
  },
  computed: {
    downloadUrl() {
      const urlParams2 = []
      if (this.sdate) {
        urlParams2.push(`sdate=${this.sdate}`)
      }
      if (this.edate) {
        urlParams2.push(`edate=${this.edate}`)
      }
      if (this.id_com_interview_status) {
        this.id_com_interview_status.forEach(e => {
          urlParams2.push(`id_com_interview_status[]=${e}`)
        })
      }
      const params = urlParams2.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/data/service_tracing/getTracingInterviews?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    status_downloadUrl() {
      const urlParams2 = []
      if (this.status_sdate) {
        urlParams2.push(`sdate=${this.status_sdate}`)
      }
      if (this.status_edate) {
        urlParams2.push(`edate=${this.status_edate}`)
      }
      if (this.id_com_meet) {
        this.id_com_meet.forEach(e => {
          urlParams2.push(`id_com_meet[]=${e}`)
        })
      }
      const params = urlParams2.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/data/service_tracing/getTracingInterviewStatuses?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
  },
  created() {
    this.getMeets()
  },
  methods: {
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: ['com_meet.id AS id', 'com_meet.title AS title'],
        where: {
          'com_meet.type': 1,
        },
      })
    },
  },
}
</script>
